import { create } from 'zustand';
import { DEVICE_ONLY_PLAN, CARE_PLAN } from 'constants/plan-constants';

export const usePlanStore = create((set) => {
    const storePlanDetails = ({ plans }) => {
        let uvDevicePlan = {};
        let carePlan = {};
        plans.forEach((plan) => {
            if (plan.name === DEVICE_ONLY_PLAN) {
                uvDevicePlan = plan;
            } else if (plan.name === CARE_PLAN) {
                carePlan = plan;
            }
        });
        set({
            plans,
            uvDevicePlanDetails: uvDevicePlan,
            carePlanDetails: carePlan,
        });
    };

    return {
        plans: [],
        uvDevicePlanDetails: {},
        carePlanDetails: {},
        selectedPlanId: null,
        isOpenCart: false,
        setPlans: (plans) => storePlanDetails({ plans }),
        setSelectedPlanId: (planId) => set({ selectedPlanId: planId }),
        setOpenCart: (isOpen) => set({ isOpenCart: isOpen }),
    };
});
