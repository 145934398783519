import { UrlParamsReplace } from '../url.service';

export const UPDATE_LEAD_URL = (leadId) =>
    UrlParamsReplace(`/leads/:leadId`, { leadId });

export const GET_LEAD_URL = (leadId) =>
    UrlParamsReplace(`/leads/:leadId`, { leadId });

export const GET_LEAD_PURCHASE_JOURNEY_URL = ({ leadId }) =>
    UrlParamsReplace(`/leads/:leadId/journey`, {
        leadId,
    });

export const REFRESH_LEAD_TOKEN = UrlParamsReplace(`/refresh-tokens`);

export const GET_PSORIASIS_ORDERS_COUNT_BY_LEAD_ID_URL = ({ leadId }) =>
    UrlParamsReplace(`/leads/:leadId/orders/count`, {
        leadId,
    });

export const GET_LEAD_DELIVERY_DETAILS_URL = ({ leadId }) =>
    UrlParamsReplace(`/leads/:leadId/delivery-details`, {
        leadId,
    });
