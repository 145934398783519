import Image from 'next/image';
import Text from '@/atoms/Text';

const LogoStrip = () => {
    return (
        <div className="bg-primary-default py-3">
            <div className="container flex items-center justify-center gap-3">
                <Image
                    src="/images/logo_bloom_w.svg"
                    width={82}
                    height={32}
                    alt=""
                />
                <Text
                    variant="body3"
                    className="text-white text-sm md:text-base"
                >
                    Supported by Dr.Reddy’s
                </Text>
                <Image
                    src="/images/logo_reddy_w.svg"
                    width={38}
                    height={32}
                    alt=""
                />
            </div>
        </div>
    );
};

export default LogoStrip;
