export const DEVICE_ONLY_PLAN = 'Device Only';
export const DEVICE_ONLY_PLAN_ID = 'b67997ca-be32-45eb-b508-8f9195c8f8c4';
export const CARE_PLAN = 'Care Plan';
export const CARE_PLAN_ID = 'b3d3b5d2-4eae-4491-9b27-f6f0946a92b6';
export const CARE_PLAN_NAME = 'careplan';
export const DEVICE_ONLY_PLAN_NAME = 'uvdevice';

export const upgradeCartPlanAdditionalDetails = {
    upgradeHeading: 'Get Better Results with Device + Care Plan',
    planImg: '/images/cart_planImg.jpg',
    duration: '12 weeks',
    subTotalText: 'Sub Total:',
    featureList: [
        { title: 'Advanced UV device' },
        { title: 'Personalised meal plans' },
        { title: 'Wellness exercises' },
    ],
};

export const uvDeviceCartPlanAdditionalDetails = {
    duration: 'for 12 weeks',
    featureList: [
        { title: 'Advanced UV device' },
        { title: 'Personalised meal plans' },
        { title: 'Wellness exercises' },
    ],
    planHeading: 'UV Wellness Plan',
    planImg: '/images/cart_planImg.jpg',
};

export const uvDeviceOnlyDetails = {
    planHeading: 'UV Device Only Plan ',
    planImg: '/images/cart_planImg.jpg',
    duration: '12 weeks',
    featureList: [
        { title: 'Dermatologist-recommended and safe to use' },
        { title: 'Compact design lets you carry it anywhere' },
        { title: 'Control over when in the day you use it' },
    ],
    price: '21,000',
};

export const uvDevicePlusCarePlan = {
    planHeading: 'UV Device + Care Plan',
    planImg: '/images/cart_planImg.jpg',
    duration: '12 weeks',
    featureList: [
        { title: 'Advanced UV therapy at home' },
        { title: 'Personalised meal plans to ease symptoms' },
        { title: 'Wellness exercises to manage stress' },
    ],
    price: '21,000',
};
export const uvDeviceMoreFeatures = [];

export const carePlanMoreFeatures = [
    {
        id: 1,
        imgSrc: '/images/icons/uv.svg',
        text: 'Get advanced UV therapy at home',
    },
    {
        id: 2,
        imgSrc: '/images/icons/cutlery.svg',
        text: 'Reduce symptoms with personalised meal plans',
    },
    {
        id: 3,
        imgSrc: '/images/icons/flower.svg',
        text: 'Manage stress with wellness exercises',
    },
];

export const planInfo = {
    careplan: CARE_PLAN_ID,
    uvdevice: DEVICE_ONLY_PLAN_ID,
};

export const psoriasisPlans = [CARE_PLAN_NAME, DEVICE_ONLY_PLAN_NAME];
