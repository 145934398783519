export const carePlanImage = '/images/icons/icon_UV_Ultra.svg';
export const uvDevicePlanImage = '/images/icons/icon_UV_Ultra.svg';
export const paymentSuccesfullHeroImage = '/images/icons/happy_play.svg';
export const planDuration = '12 weeks';
export const carePlanFeatures = [
    { item: 'Advanced UV device' },
    { item: 'Personalized meal plans' },
    { item: 'Wellness exercises' },
];
export const planTaxes = 12;

export const deviceOnlyPlanFeatures = [{ item: 'Advanced UV device' }];
export const deviceOnlyPlanFeature = [{ title: 'Advanced UV device' }];
