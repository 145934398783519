import { getCookie, setCookie } from 'cookies-next';
import * as HttpService from './http.service';
import {
    getAuth,
    getRefreshToken,
    removeAuth,
    removeRT,
    setAuth,
} from './identity.service';
import {
    GET_LEAD_PURCHASE_JOURNEY_URL,
    GET_LEAD_URL,
    REFRESH_LEAD_TOKEN,
    UPDATE_LEAD_URL,
    GET_PSORIASIS_ORDERS_COUNT_BY_LEAD_ID_URL,
    GET_LEAD_DELIVERY_DETAILS_URL,
} from './urls/profile.url';
import { parseData } from 'utils/common';
import { Router } from 'next/router';

export const updateLead = (leadId, details) => {
    return HttpService.putWithAuth(UPDATE_LEAD_URL(leadId), details);
};

export const getLead = (leadId, auth, ctx) => {
    return HttpService.getWithAuth(GET_LEAD_URL(leadId), auth, ctx);
};

export const getLeadPurchaseJourney = ({ leadId, planId, auth, ctx }) => {
    return HttpService.getWithAuth(
        GET_LEAD_PURCHASE_JOURNEY_URL({ leadId, planId }),
        auth,
        ctx
    );
};

export const refreshUserToken = (leadId, refreshToken) => {
    return HttpService.postWithOutAuth(REFRESH_LEAD_TOKEN, {
        leadId,
        refreshToken,
    });
};

export const refreshTokenAndSetAuth = async (callback, authDetails, ctx) => {
    const auth = authDetails ?? getAuth();

    const refreshToken = ctx
        ? getCookie('RT', {
              req: ctx.req,
              res: ctx.res,
          })
        : getRefreshToken();

    const response = await refreshUserToken(auth.id, refreshToken);
    if (response.status) {
        const data = response.entity;
        const lead = {
            id: data.id,
            id_token: data.id_token,
            name: data.name,
            access_token: data.access_token,
            token: data.id_token,
            mobileNumber: data.mobileNumber,
        };

        if (typeof window === 'undefined') {
            await setCookie(
                'AUTH',
                JSON.stringify({
                    ...parseData(
                        getCookie('AUTH', { req: ctx.req, res: ctx.res })
                    ),
                    ...lead,
                }),
                {
                    req: ctx.req,
                    res: ctx.res,
                }
            );
        } else {
            await setAuth(lead);
        }

        const res = await callback();
        return res;
    }
    if (
        !response.status &&
        response.errorResponse?.message === 'Failed to get new access tokens!'
    ) {
        removeRT();
        removeAuth();
        Router.push('/login');
    }
};

export const getPsoriasisOrdersCountByLeadId = ({ leadId }) => {
    return HttpService.getWithOutAuth(
        GET_PSORIASIS_ORDERS_COUNT_BY_LEAD_ID_URL({ leadId })
    );
};

export const getLeadDeliveryDetailsByLeadId = ({ leadId, auth, ctx }) => {
    return HttpService.getWithAuth(
        GET_LEAD_DELIVERY_DETAILS_URL({ leadId }),
        auth,
        ctx
    );
};
