import { refreshTokenAndSetAuth } from '@/services/profile.service';
import axios from 'axios';
import { checkForTokenExpiredError, parseData } from 'utils/common';
import { getAuth } from './identity.service';
import { getCookie } from 'cookies-next';

export const postWithAuth = async (url, entity, contentType) => {
    const auth = await getAuth();

    return postAuthWithAccessToken(url, auth?.id_token, entity, contentType);
};

export const postWithAuthOrStaticToken = async (url, entity, contentType) => {
    const auth = await getAuth();
    const staticToken = process.env.NEXT_PUBLIC_JWT_TOKEN;
    return postAuthWithAccessToken(
        url,
        auth?.id_token,
        entity,
        contentType,
        staticToken
    );
};

export const putWithAuth = async (url, entity) => {
    const auth = await getAuth();

    return putAuthWithAccessToken(url, auth?.id_token, entity);
};

export const getWithAuth = async (url, authDetails, ctx) => {
    const auth = authDetails ?? getAuth();
    return getAuthWithAccessToken(url, auth?.id_token, auth, ctx);
};

export const getWithOutAuth = (url) => {
    return new Promise((resolve) => {
        const headers = {
            'content-type': 'application/json',
        };
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({
                    status: false,
                    message: ex?.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });
};

export const postWithOutAuth = (url, entity) =>
    new Promise((resolve) => {
        const headers = {
            'content-type': 'application/json',
        };

        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({
                    status: false,
                    message: ex.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });

export const putWithOutAuth = (url, entity) =>
    new Promise((resolve) => {
        const headers = {
            'content-type': 'application/json',
        };

        axios
            .put(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({
                    status: false,
                    message: ex.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });

export const post = ({
    url,
    headers = {
        'content-type': 'application/json',
    },
    entity,
    params = {},
}) =>
    new Promise((resolve) => {
        axios
            .post(url, entity, { headers, params })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({
                    error: ex,
                    status: false,
                    message: ex.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });

export const getAuthWithAccessToken = (url, accessToken, auth, ctx) => {
    return new Promise((resolve) => {
        const headers = {
            'content-type': 'application/json',
            'x-access-token': accessToken,
        };
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                if (checkForTokenExpiredError(ex)) {
                    const callback = () =>
                        getWithAuth(
                            url,
                            ctx
                                ? parseData(
                                      getCookie('AUTH', {
                                          req: ctx.req,
                                          res: ctx.res,
                                      })
                                  )
                                : getAuth(),
                            ctx
                        );
                    refreshTokenAndSetAuth(
                        callback,
                        ctx
                            ? parseData(
                                  getCookie('AUTH', {
                                      req: ctx.req,
                                      res: ctx.res,
                                  })
                              )
                            : getAuth(),
                        ctx
                    ).then((data) => {
                        return resolve(data);
                    });

                    return;
                }
                resolve({
                    status: false,
                    message: ex.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });
};

export const postAuthWithAccessToken = (
    url,
    accessToken,
    entity,
    contentType,
    staticToken
) => {
    const headers = {
        'content-type': contentType ?? 'application/json',
        ...(accessToken
            ? {
                  'x-access-token': accessToken,
              }
            : {}),
        ...(staticToken
            ? {
                  'jwt-access-token': staticToken,
              }
            : {}),
    };

    return new Promise((resolve) => {
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
                // Handling for s3 upload as response.data is ''
                if (response?.data === '')
                    resolve({
                        statusCode: response.status,
                    });
            })
            .catch((ex) => {
                if (checkForTokenExpiredError(ex)) {
                    const callback = () => postWithAuth(url, entity);
                    refreshTokenAndSetAuth(callback).then((data) =>
                        resolve(data)
                    );
                    return;
                }
                resolve({
                    status: false,
                    message: ex.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });
};

export const putAuthWithAccessToken = (url, accessToken, entity) => {
    const headers = {
        'content-type': 'application/json',
        'x-access-token': accessToken,
    };
    return new Promise((resolve) => {
        axios
            .put(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                if (checkForTokenExpiredError(ex)) {
                    const callback = () => putWithAuth(url, entity);
                    refreshTokenAndSetAuth(callback).then((data) =>
                        resolve(data)
                    );
                    return;
                }
                resolve({
                    status: false,
                    message: ex.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });
};

export const deleteWithOutAuth = (url) => {
    return new Promise((resolve) => {
        const headers = {
            'content-type': 'application/json',
        };

        axios
            .delete(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({
                    status: false,
                    message: ex?.message,
                    errorMessage: ex?.response?.data?.message,
                    statusCode: ex?.response?.status,
                });
            });
    });
};

export const hasWindow = () => {
    return typeof window === 'object';
};
