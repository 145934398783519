import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from './tick.json';

const LottieAnimation = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: containerRef.current,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: animationData,
        });

        return () => {
            anim.destroy();
        };
    }, []);

    return (
        <div ref={containerRef} style={{ width: '70px', height: '70px' }}></div>
    );
};

const CheckMarkAnimation = () => {
    return (
        <div className="flex justify-center items-center mb-8">
            <LottieAnimation />
        </div>
    );
};

export default CheckMarkAnimation;
