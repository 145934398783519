import React from 'react';
import Button from '@/atoms/Button';
import PropTypes from 'prop-types';
import Modal from './Modal';
import Link from 'next/link';

const ModalWithPdf = ({ isShowModal, closeModal, pdfTitle, pdfLink }) => {
    return (
        <Modal
            isShowModal={isShowModal}
            closeModal={closeModal}
            headerTitle={pdfTitle}
            footerButtons={false}
            width="max-w-full sm:max-w-[620px]"
            height=""
            bodyClass="text-center pb-6 px-4 sm:px-20"
        >
            <div className="h-[60vh] sm:h-[65vh] overflow-auto">
                <iframe
                    src={`${pdfLink}#toolbar=0`}
                    className="w-full h-full border-none"
                    frameborder="0"
                ></iframe>
            </div>
            <Link
                href={pdfLink}
                className="text-center"
                target="_blank"
                download
            >
                <Button
                    style="borderLess"
                    isIcon
                    size="medium"
                    iconSrc="/images/icons/icon_download.svg"
                    label={`Download ${pdfTitle.toLowerCase()}`}
                    btnClass="flex-row-reverse items-center mx-auto mt-4"
                />
            </Link>
        </Modal>
    );
};

ModalWithPdf.defaultProps = {
    isShowModal: false,
    closeModal: () => {},
    pdfTitle: 'Privacy Policy',
    pdfLink: '',
};

ModalWithPdf.propTypes = {
    isShowModal: PropTypes.bool,
    closeModal: PropTypes.func,
    pdfTitle: PropTypes.string,
    pdfLink: PropTypes.string,
};

export default ModalWithPdf;
