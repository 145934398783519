import { router } from 'next/router';

export const redirectTo = (destination, { res, status } = {}) => {
    if (res) {
        // Server-side redirect
        res.writeHead(status || 302, { Location: destination });
        res.end();
    } else {
        // Client-side redirect
        if (destination.startsWith('/')) {
            router.push(destination);
        } else {
            window.location = destination;
        }
    }
};

export const goBack = () => {
    router.back();
};
