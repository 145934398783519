import { useRequestCallbackStore } from 'store/requestCallback';
import { getAuth } from './identity.service';
import { ZOHO_DEPARTMENT_ID } from 'constants/general-constants';
import { createTicketToZoho } from './zoho-create-ticket.service';

const useRequestCallbackHook = () => {
    const {
        setIsOpenRequestcallback,
        setRequestcallbackDescription,
        setIsRequestMessagaeRecieve,
    } = useRequestCallbackStore();

    const handleTicket = async (description) => {
        if (!getAuth()?.id_token) {
            setIsRequestMessagaeRecieve(false);
            setIsOpenRequestcallback(true);
            setRequestcallbackDescription(description);
            return;
        }
        const entity = {
            departmentId: ZOHO_DEPARTMENT_ID,
            subject: 'Website - Callback Request',
            contact: {
                lastName: getAuth().name,
                phone: getAuth().mobileNumber,
            },
            description,
        };
        const response = await createTicketToZoho(entity);
        if (response.status) {
            setIsRequestMessagaeRecieve(true);
            setIsOpenRequestcallback(true);
        }
    };
    return { handleTicket };
};

export default useRequestCallbackHook;
