import React, { useEffect } from 'react';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Button from '@/atoms/Button';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MobileMenu = ({
    list,
    handleClose = () => {},
    show = false,
    handleTrackOrder = () => {},
    handleCallBack = () => {},
}) => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false, // Ensure animations can re-trigger
        });
    }, []);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
            AOS.refreshHard(); // Refresh animations when the menu is shown
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [show]);

    return (
        show && (
            <div
                className={`fixed z-[200] left-0 right-0 top-0 bottom-0 bg-grey-shade1 bg-opacity-70 h-screen w-screen transform duration-500 ease-linear`}
            >
                <div
                    className={`w-[70%] flex flex-col gap-10 justify-between bg-white rounded-tl-3xl rounded-bl-3xl h-[100dvh] fixed right-0 p-5 md:p-10 transform duration-500 ease-linear overflow-y-auto`}
                    data-aos="fade-left"
                    data-aos-delay={500}
                    data-aos-once="true"
                >
                    <div>
                        <div className="flex justify-end mb-10">
                            <NextImage
                                src="/images/icons/icon_close.svg"
                                width={44}
                                height={44}
                                alt="close icon"
                                className="cursor-pointer"
                                onClick={handleClose}
                            />
                        </div>

                        <ul>
                            {list.map((item, index) => (
                                <li
                                    className={`text-lg/[23px] font-medium border-b border-grey-shade5 last:border-none py-4 px-5 cursor-pointer  ${item.isActive ? 'text-blue' : 'text-grey-shade4'}`}
                                    key={item.id}
                                    data-aos="fade-up"
                                    data-aos-delay={index * 400}
                                    data-aos-once="true"
                                >
                                    <Link href={item.href}>{item.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-col gap-6 pb-10">
                        <Button
                            style="ghost"
                            label="Request a callback"
                            size="medium"
                            btnClass="flex-1"
                            onClick={handleCallBack}
                        />
                        <Button
                            style="ghost"
                            label="Track Order"
                            size="medium"
                            btnClass="flex-1"
                            onClick={handleTrackOrder}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired,
            title: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        })
    ).isRequired,
    handleClose: PropTypes.func,
    handleCallBack: PropTypes.func,
    handleTrackOrder: PropTypes.func,
};

export default MobileMenu;
