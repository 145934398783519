export const NAVBAR_MESSAGE = 'on the navbar';
export const LONG_LASTING_MESSAGE =
    'Long-lasting relief that does not interrupt your life';
export const DEVICE_ONLY_PLAN_HOME_PAGE_MESSAGE =
    '“Device Only Plan“ in Plans and Pricing section on the Home Page';
export const CARE_PLAN_HOME_PAGE_MESSAGE =
    '“Device + Care Plan“ in Plans and Pricing section on the Home Page';
export const DEVICE_ONLY_PLAN_PLANID_PAGE_MESSAGE =
    '“UV Device Only Plan | What it does“ section on the Buy UV Device Page';
export const CARE_PLAN_PLANID_PAGE_MESSAGE =
    '“UV Device + Care Plan | What it does“ section on the Buy Care Plan Page';
export const FAQ_PAGE_MESSAGE = 'on FAQ page';
export const DEVICE_ONLY_PLAN_PURCHASE_PAGE_MESSAGE =
    'on device only plan card on purchase history page';
export const CARE_PLAN_PURCHASE_PAGE_MESSAGE =
    'on care plan card on purchase history page';
export const CARE_PLAN_UPGRADED_CARD_PURCHASE_PAGE_MESSAGE =
    'on care plan upgraded card on purchase history page';
