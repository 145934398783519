import Link from 'next/link';
import PropTypes from 'prop-types';
import Text from './Text';

const MenuList = ({ className, menuItem }) => {
    return (
        <nav className={`w-full ${className}`}>
            <ul className="flex space-x-10">
                {menuItem.map((menu, index) => (
                    <li key={index}>
                        {menu.href ? (
                            <Link
                                href={menu.href}
                                className={`relative cursor-pointer hover:text-primary-900 transition-all ease-in-out duration-700 after:content-[''] after:absolute after:w-full after:h-0.5 after:-bottom-2.5 after:left-0  ${menu.isActive ? 'text-blue after:bg-blue' : 'after:bg-transparent '}`}
                            >
                                {menu.title}
                            </Link>
                        ) : (
                            <span className="cursor-not-allowed">
                                <Text variant="bodySmall">{menu.title}</Text>
                            </span>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

MenuList.propTypes = {
    className: PropTypes.string,
    menuItem: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};

MenuList.defaultProps = {
    className: '',
    menuItem: [],
};

export default MenuList;
