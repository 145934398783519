import { useMutation, useQuery } from '@tanstack/react-query';
import {
    getLead,
    updateLead,
    getLeadPurchaseJourney,
    getPsoriasisOrdersCountByLeadId,
} from './profile.service';

export const useUpdateLeadMutation = ({
    onSuccessfulEmailUpdate,
    setEmailError,
}) => {
    const updateLeadMutation = useMutation({
        mutationFn: ({ leadId, details }) => updateLead(leadId, details),
        onSuccess: async (_data) => {
            if (_data.status) {
                onSuccessfulEmailUpdate();
            } else {
                setEmailError(_data.errorMessage ?? 'Failed to update details');
            }
        },
    });
    return { updateLeadMutation };
};

export const useGetLead = (leadId) => {
    return useQuery(['leads', leadId], async () => {
        const data = await getLead(leadId);
        return data.status ? data?.entity : [];
    });
};

export const useGetLeadPurchaseJourney = ({ leadId, planId, auth }) => {
    return useQuery(['leads', leadId, 'plans', planId], async () => {
        const data = await getLeadPurchaseJourney({ leadId, planId, auth });
        return data.status ? data?.entity : {};
    });
};

export const useGetLeadPurchaseJourneyMutation = ({ onSuccess }) => {
    const getLeadPurchaseJourneyMutation = useMutation({
        mutationFn: ({ leadId, planId, auth }) =>
            getLeadPurchaseJourney({ leadId, planId, auth }),
        onSuccess: async (_data) => {
            if (_data.status) {
                onSuccess(_data.entity.paymentOrderId);
            }
        },
    });
    return { getLeadPurchaseJourneyMutation };
};

export const useGetPsoriasisOrdersCountByLeadId = ({ onGetOrdersCount }) => {
    const getPsoriasisOrdersCountByLeadIdMutation = useMutation({
        mutationFn: ({ leadId }) => getPsoriasisOrdersCountByLeadId({ leadId }),
        onSuccess: async (_data) => {
            if (_data.status) {
                onGetOrdersCount(_data?.entity || 0);
            }
            return;
        },
    });
    return { getPsoriasisOrdersCountByLeadIdMutation };
};

export const useGetPsoriasisOrdersCountByLeadIdQuery = ({ leadId }) => {
    return useQuery(['leads', leadId, 'orders', 'count'], async () => {
        const data = await getPsoriasisOrdersCountByLeadId({ leadId });
        return data.status ? data?.entity : 0;
    });
};
