import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Text from './Text';

const Checkbox = ({
    id,
    name,
    className,
    handleChange,
    isSelected,
    value,
    checkBoxLabel,
    hideLabel,
    checkBoxDisable = false,
    isError = false,
    containerClass = '',
    errorMessage = 'Error message',
    labelClassName = '',
}) => {
    return (
        <>
            <div
                className={classnames(
                    'relative cursor-pointer',
                    checkBoxDisable && 'pointer-events-none',
                    containerClass
                )}
            >
                <input
                    id={id}
                    name={name}
                    onChange={handleChange}
                    value={value}
                    type="checkbox"
                    checked={isSelected}
                    className="absolute opacity-0 w-full h-full left-0 top-0 z-10 cursor-pointer"
                />

                <div className="flex items-start cursor-pointer">
                    <div
                        className={classnames(
                            className,
                            'flex flex-shrink-0 justify-center items-center relative w-4 h-4 mt-0.5 rounded border',
                            {
                                'border-neutral-400': !isSelected && !isError,
                                'bg-primary-default border-primary-default':
                                    isSelected && !checkBoxDisable,
                                'bg-grey-shade5 border-grey-shade5 pointer-events-none':
                                    checkBoxDisable,
                                'border-error-100': isError,
                            }
                        )}
                    >
                        {isSelected && (
                            <Icon
                                src="/images/icons/icon_check_w.svg"
                                width={16}
                                height={16}
                                alt="check"
                            />
                        )}
                    </div>

                    <Text className={`ml-2 ${labelClassName}`}>
                        {!hideLabel && checkBoxLabel}
                    </Text>
                </div>
            </div>
            {isError && (
                <div className="block text-xs text-error-100 mt-1">
                    {errorMessage}
                </div>
            )}
        </>
    );
};

Checkbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    handleChange: PropTypes.func,
    isSelected: PropTypes.bool,
    isError: PropTypes.bool,
    value: PropTypes.string,
    checkBoxLabel: PropTypes.string,
    hideLabel: PropTypes.bool,
    showIcon: PropTypes.bool,
    checkBoxDisable: PropTypes.bool,
    checkBoxLabelPos: PropTypes.oneOf(['left', 'right']),
    labelClassName: PropTypes.string,
    containerClass: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default Checkbox;
