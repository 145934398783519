import PropTypes from 'prop-types';
import SlideOver from './SlideOver';
import CartList from './CartList';
import UpgradeCart from './UpgradeCart';
import StickyButton from '@/atoms/StickyButton';
import { isObjectEmpty } from 'helpers/object';
import EmptyCart from './EmptyCart';

const Cart = ({
    cartPlan,
    upgradeCartPlan,
    isOpenCart,
    setOpenCart,
    handleBuy,
    onUpgradePlan,
}) => {
    return (
        <SlideOver
            headerTitle="Cart"
            bodyClass="pb-[150px]"
            isSlideOverOpen={isOpenCart}
            setSlideOverOpen={setOpenCart}
        >
            {isObjectEmpty(cartPlan) ? (
                <form className="flex flex-col gap-10">
                    <EmptyCart />
                    <StickyButton
                        label="Back"
                        handleSubmit={() => setOpenCart(false)}
                    />
                </form>
            ) : (
                <form className="flex flex-col gap-10">
                    <CartList plan={cartPlan} />
                    {!isObjectEmpty(upgradeCartPlan) && (
                        <UpgradeCart
                            plan={upgradeCartPlan}
                            onUpgradePlan={onUpgradePlan}
                        />
                    )}
                    <StickyButton label="Buy Now" handleSubmit={handleBuy} />
                </form>
            )}
        </SlideOver>
    );
};

Cart.defaultProps = {
    cartPlan: {},
    upgradeCartPlan: {},
    isOpenCart: true,
    handleBuy: () => {},
    setOpenCart: () => {},
    onUpgradePlan: () => {},
};

Cart.propTypes = {
    cartPlan: PropTypes.shape({
        planImg: PropTypes.string,
        planName: PropTypes.string,
        featureList: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
            })
        ),
        price: PropTypes.string,
    }),
    upgradeCartPlan: PropTypes.shape({
        planImg: PropTypes.string,
        planHeading: PropTypes.string,
        duration: PropTypes.string,
        featureList: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
            })
        ),
        subTotalText: PropTypes.string,
        price: PropTypes.string,
    }),
    isOpenCart: PropTypes.bool,
    handleBuy: PropTypes.func,
    setOpenCart: PropTypes.func,
    onUpgradePlan: PropTypes.func,
};

export default Cart;
