import Image from 'next/image';
import Text from '@/atoms/Text';

const EmptyCart = () => {
    const myLoader = ({ src }) => src;
    return (
        <div className="text-center">
            <Image
                loader={myLoader}
                src="/images/empty_cart.png"
                width={280}
                height={280}
                alt=""
                className="mx-auto"
            />
            <Text variant="subHeading" className="mb-1">
                Your cart is empty
            </Text>
            <Text variant="body1">
                Check out our plans for psoriasis management
            </Text>
        </div>
    );
};

export default EmptyCart;
