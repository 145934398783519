import PropTypes from 'prop-types';

const InputBox = ({
    isError,
    errorMessage,
    className,
    inputClass,
    variant,
    disabled,
    type,
    register,
    id,
    dbName,
    isLabel,
    labelText,
    labelClass,
    placeholder,
    ...otherProps
}) => {
    const inputSizeStyles = {
        Default: 'py-2 px-3',
        Small: 'py-1.5 px-3',
        Large: 'px-4 py-3',
    };

    const inputDisabledClass = disabled
        ? 'cursor-not-allowed disabled:bg-grey-shade5 disabled:border-grey-shade5 disabled:placeholder:text-grey-shade4'
        : 'cursor-pointer';

    const inputBorderStyle = isError
        ? 'border-error-100 hover:border-error-100 focus:border-error-100'
        : 'border-grey-shade4 hover:border-grey-shade3 focus:border-grey-shade3';

    return (
        <div className={`relative ${inputClass}`}>
            {isLabel && (
                <label
                    htmlFor={id}
                    className={`text-grey-shade1 text-base/[22px] block pb-3 ${labelClass}`}
                >
                    {labelText}
                </label>
            )}

            <input
                type={type}
                placeholder={placeholder}
                {...(register && { ...register(dbName) })}
                className={`
          ${inputSizeStyles[variant]}
          hideArrow w-full border bg-white rounded-lg outline-none placeholder:text-grey-shade4 text-grey-shade1 text-base/[22px] font-normal appearance-none
          ${inputDisabledClass}
          ${inputBorderStyle}
          select-none
          ${className}
        `}
                disabled={disabled}
                {...otherProps}
            />
            {isError && (
                <span className="text-error-100 pl-0.5 text-sm pt-2 font-normal leading-[21px] select-none">
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

InputBox.propTypes = {
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    inputClass: PropTypes.string,
    variant: PropTypes.oneOf(['Default', 'Small', 'Large']),
    disabled: PropTypes.bool,
    type: PropTypes.string,
    register: PropTypes.func,
    id: PropTypes.string,
    dbName: PropTypes.string,
    isLabel: PropTypes.bool,
    labelText: PropTypes.string,
    labelClass: PropTypes.string,
    placeholder: PropTypes.string,
};

InputBox.defaultProps = {
    isError: false,
    className: '',
    inputClass: 'w-full',
    variant: 'Default',
    disabled: false,
    type: 'text',
    labelClass: 'font-normal',
};

export default InputBox;
