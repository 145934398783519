import PropTypes from 'prop-types';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Image from 'next/image';
import Lists from '@/molecules/Lists';
import Tag from '@/atoms/Tag';

const CartList = ({ plan }) => {
    const myLoader = ({ src }) => src;
    return (
        <div className="flex px-4 py-6 md:px-6 gap-5 items-center border border-grey-shade5 bg-white rounded-xl">
            <Image
                loader={myLoader}
                src={plan.planImg}
                width={170}
                height={170}
                alt="blog image"
                className="flex shrink-0 rounded-xl max-w-[120px] md:max-w-[170px]"
            />
            <div className="w-full">
                <div className="flex flex-wrap items-center justify-between gap-3 mb-4">
                    <Heading
                        type="h5"
                        fontFamily="font-body"
                        fontWeight="font-medium"
                        fontColor="text-grey-shade1"
                    >
                        {plan.planHeading}
                    </Heading>

                    <Tag
                        icon={false}
                        className="text-grey-shade1 p-1 whitespace-pre"
                        style="primary"
                        shape="soft"
                        size="extraSmall"
                    >
                        <span className="font-medium ml-1">
                            {plan.duration}
                        </span>
                    </Tag>
                </div>

                <Text
                    variant="body-1"
                    fontWeight="font-medium"
                    className="mb-2"
                >
                    You get:
                </Text>
                <Lists
                    listIcon="/images/icons/icon_check.svg"
                    lists={plan.featureList}
                />
                <hr className="border-grey-shade4 mt-4 mb-5" />
                <div className="flex  items-center gap-x-6 justify-between">
                    <Text variant="body3">Sub Total:</Text>
                    <Heading
                        type="h2"
                        fontWeight="font-normal"
                        fontFamily="font-sectionHeading"
                        fontColor="text-grey-shade1"
                        fontSize="text-h3 md:text-h2"
                    >
                        ₹{plan.price}
                    </Heading>
                </div>
            </div>
        </div>
    );
};

CartList.defaultProps = {
    plan: {},
};

CartList.propTypes = {
    plan: PropTypes.shape({
        planImg: PropTypes.string,
        planHeading: PropTypes.string,
        duration: PropTypes.string,
        featureList: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
            })
        ),
        price: PropTypes.string,
    }),
};

export default CartList;
