import PropTypes from 'prop-types';

const Text = ({
    variant,
    fontSize,
    fontWeight,
    textColor,
    children,
    className,
    onClick,
    html,
}) => {
    const TextStyle = {
        body1: 'text-sm/[21px]',
        body2: 'text-base/[22px]',
        body3: 'text-base/[22px] md:text-lg/[27px]',
        body4: 'text-base',
        caption: 'text-xs/[14px]',
        captionSmall: 'text-[10px]/[16px] tracking-[2%]',
        subHeading: 'text-lg/[27px] md:text-xl/[24px]',
    };

    const fontSizeStyle = fontSize
        ? fontSize
        : TextStyle[variant] || TextStyle.body1;

    return html ? (
        <div
            className={`${fontSizeStyle} ${fontWeight} ${textColor} ${className}`}
            onClick={onClick}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    ) : (
        <div
            className={`${fontSizeStyle} ${fontWeight} ${textColor} ${className}`}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

Text.defaultProps = {
    variant: 'body',
    fontSize: '',
    fontWeight: 'font-normal',
    textColor: 'text-grey-shade3',
    children: '',
    className: '',
    onClick: () => {},
};

Text.propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    textColor: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    html: PropTypes.string,
};

export default Text;
