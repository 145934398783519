import { useMutation, useQuery } from '@tanstack/react-query';
import { setAuth } from './identity.service';
import { CARE_PLAN, DEVICE_ONLY_PLAN } from 'constants/plan-constants';
import { getPlans } from './plans.service';

export const useGetPlansMutation = () => {
    const getPlansMutation = useMutation({
        mutationFn: () => getPlans(),
        onSuccess: async (plansResponse) => {
            if (plansResponse.status) {
                const uvDevicePlanDetails = plansResponse.entity.find(
                    (plan) => plan.name === DEVICE_ONLY_PLAN
                );
                const carePlanDetails = plansResponse.entity.find(
                    (plan) => plan.name === CARE_PLAN
                );
                setAuth({
                    uvDevicePlanId: uvDevicePlanDetails.id,
                    carePlanId: carePlanDetails.id,
                });
            }
        },
    });
    return {
        getPlansMutation,
    };
};

export const useGetPlans = () =>
    useQuery(['plans'], async () => {
        const data = await getPlans();
        return data.status ? data?.entity : [];
    });
