import PropTypes from 'prop-types';
import Button from './Button';

const StickyButton = ({
    label,
    handleSubmit,
    isSecondaryBtn,
    secondaryBtnLabel,
    handleSecondaryBtn,
    type = 'button',
    disabled,
}) => {
    return (
        <div className="fixed left-0 bottom-0 px-5 py-8 md:px-10 md:py-10 bg-white w-full flex flex-col gap-5">
            {isSecondaryBtn && (
                <Button
                    disabled={disabled}
                    label={secondaryBtnLabel}
                    style="ghost"
                    size="large"
                    btnClass="w-full"
                    onClick={handleSecondaryBtn}
                />
            )}
            <Button
                disabled={disabled}
                label={label}
                size="large"
                btnClass="w-full"
                onClick={handleSubmit}
                type={type}
            />
        </div>
    );
};

StickyButton.defaultProps = {
    label: 'Buy Now',
    handleSubmit: () => {},
    isSecondaryBtn: false,
    secondaryBtnLabel: '',
    handleSecondaryBtn: () => {},
};

StickyButton.propTypes = {
    label: PropTypes.string,
    handleSubmit: PropTypes.func,
    isSecondaryBtn: PropTypes.bool,
    secondaryBtnLabel: PropTypes.string,
    handleSecondaryBtn: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.string,
};
export default StickyButton;
