import { create } from 'zustand';

export const useProfileStore = create((set) => {
    return {
        lead: {},
        setLead: (lead) => set({ lead }),
        ordersCount: 0,
        setOrdersCount: (ordersCount) => set({ ordersCount }),
    };
});
