import PropTypes from 'prop-types';
import { useRef } from 'react';
import Button from '../atoms/Button';
import useClickOutside from './Common/ClickOutSide';
import Heading from '@/atoms/Heading';
import Icon from '@/atoms/Icon';

const Modal = ({
    isShowModal,
    children,
    isHeader,
    headerTitle,
    headerClass,
    width,
    height,
    closeModal,
    isFooter,
    bodyClass,
    footerButtons,
}) => {
    const dialogRef = useRef();
    useClickOutside(dialogRef, closeModal);
    return (
        isShowModal && (
            <div
                className={`dialog fixed flex items-center justify-center w-full h-full top-0 left-0 bg-grey-shade1 bg-opacity-70 z-[1000] px-4 md:px-0`}
            >
                <div
                    ref={dialogRef}
                    className={`bg-white w-full h-auto rounded-xl ${width} ${height} `}
                >
                    {isHeader && (
                        <div
                            className={`flex justify-between items-center px-4 py-6 md:px-5 md:py-5 ${headerClass}`}
                        >
                            <Heading
                                type="h3"
                                fontFamily="font-body md:font-heading"
                                fontSize="text-subHeading md:text-h3"
                            >
                                {headerTitle}
                            </Heading>
                            <span
                                onClick={closeModal}
                                className="w-10 md:w-11 cursor-pointer"
                            >
                                <Icon
                                    src="/images/icons/icon_close.svg"
                                    width={44}
                                    height={44}
                                    alt="closeModal"
                                />
                            </span>
                        </div>
                    )}

                    <div className={bodyClass}>{children}</div>
                    {isFooter && (
                        <div
                            className={`flex justify-end gap-2  px-6 py-4 ${
                                isHeader && 'border-t'
                            }`}
                        >
                            {footerButtons?.map((button, i) => {
                                return (
                                    <Button
                                        key={i}
                                        style={button.style}
                                        label={button.label}
                                        size={button.size}
                                        onClick={button.onClick}
                                        className={button.className}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default Modal;

Modal.defaultProps = {
    isShowModal: true,
    children: 'Body',
    headerTitle: 'Dialog Box',
    isHeader: true,
    headerClass: '',
    width: 'max-w-[420px] ',
    height: 'h-max',
    closeModal: () => {},
    isFooter: false,
    bodyClass: '',
    footerButtons: [
        {
            label: 'No',
            style: 'ghost',
            size: 'extraSmall',
            className: 'rounded',
            onClick: () => {},
        },
        {
            label: 'Yes',
            style: 'primary',
            size: 'extraSmall',
            className: 'rounded',
            onClick: () => {},
        },
    ],
};

Modal.propTypes = {
    isShowModal: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    headerTitle: PropTypes.string,
    isHeader: PropTypes.bool,
    headerClass: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    closeModal: PropTypes.func,
    isFooter: PropTypes.bool,
    bodyClass: PropTypes.string,
    footerButtons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            style: PropTypes.string,
            size: PropTypes.string,
            className: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
};
