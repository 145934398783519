import * as HttpService from './http.service';
import {
    ZOHO_CREATE_TICKET_URL,
    ZOHO_GENERATE_ACCESS_TOKEN_URL,
    ZOHO_UPLOAD_FILES_URL,
} from './url.service';

export const createTicketToZoho = async (entity) => {
    return HttpService.postWithAuthOrStaticToken(ZOHO_CREATE_TICKET_URL, {
        data: entity,
    });
};

export const uploadFilesToZoho = async (formData) => {
    return HttpService.postWithAuthOrStaticToken(
        ZOHO_UPLOAD_FILES_URL,
        formData,
        'multipart/form-data'
    );
};

export const generateZohoAccessToken = () => {
    return HttpService.postWithAuthOrStaticToken(
        ZOHO_GENERATE_ACCESS_TOKEN_URL,
        {}
    );
};
