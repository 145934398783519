import PropTypes from 'prop-types';
import Checkbox from '@/atoms/Checkbox';
import InputBox from '@/atoms/InputBox';
import SectionHeading from '@/atoms/SectionHeading';
import Text from '@/atoms/Text';
import Image from 'next/image';
import SlideOver from './SlideOver';
import StickyButton from '@/atoms/StickyButton';
import CheckMarkAnimation from '@/atoms/CheckMarkAnimation';
import { ZOHO_DEPARTMENT_ID } from 'constants/general-constants';
import { createTicketToZoho } from '@/services/zoho-create-ticket.service';
import { useState } from 'react';
import { useRequestCallbackStore } from 'store/requestCallback';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const RequestCallback = ({
    isOpenRequestCallback,
    setOpenRequestCallback,
    onClose,
    isRequestRaised = false,
}) => {
    const { requestCallbackDescription } = useRequestCallbackStore();
    const [isCallbackRecieve, setIsCallbackRecieve] = useState(isRequestRaised);

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Name is required')
            .test(
                'len',
                'Name must be 2-30 characters',
                (val) => val && val.length >= 2 && val.length <= 30
            ),
        acknowledge: yup
            .boolean()
            .test('error', 'You must accept acknowledgement', (val) => {
                return val;
            }),
        mobNumber: yup
            .string()
            .required('Mobile number is required')
            .matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    });

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
        trigger,
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            acknowledge: true,
            smsConfirmation: true,
        },
    });

    const handleRequestCallback = async (data) => {
        const entity = {
            departmentId: ZOHO_DEPARTMENT_ID,
            subject: 'Website - Callback Request',
            contact: {
                lastName: data.name,
                phone: data.mobNumber,
            },
            description: requestCallbackDescription,
        };
        const response = await createTicketToZoho(entity);
        if (response.status) {
            setIsCallbackRecieve(true);
        }
    };
    return (
        <SlideOver
            isSlideOverOpen={isOpenRequestCallback}
            onClose={onClose}
            setSlideOverOpen={setOpenRequestCallback}
            headerTitle=""
            slideOverClass="p-0"
            bodyBaseClass="pt-0 pb-[100px]"
            headerClass="border-none absolute w-full z-20 right-5"
        >
            <Image
                src="/images/callback_header.svg"
                width={610}
                height={191}
                alt=""
                objectFit="flex fill"
                className="w-full"
            />

            <div className="p-10">
                {isCallbackRecieve ? (
                    <div className="flex flex-col items-center justify-center text-center pt-24">
                        <CheckMarkAnimation />
                        <Text
                            variant="subHeading"
                            textColor="text-gray-shade1"
                            className="mb-3 max-w-[257px] mx-auto"
                            fontWeight="font-medium"
                        >
                            Thanks for showing interest!
                        </Text>
                        <Text variant="body1" className="mb-8">
                            Our representative will reach out to you soon
                        </Text>
                    </div>
                ) : (
                    <>
                        <SectionHeading>Request a callback</SectionHeading>
                        <Text variant="body2" className="mb-8">
                            Share your details below and we will reach out to
                            you soon
                        </Text>
                        <form>
                            <div className="flex flex-col gap-7 ">
                                <InputBox
                                    id="name"
                                    name="name"
                                    dbName="name"
                                    type="text"
                                    isLabel={true}
                                    labelText="Enter Name"
                                    placeholder=""
                                    register={register}
                                    errorMessage={
                                        errors?.name && errors.name?.message
                                    }
                                    isRequired={true}
                                    isError={errors?.name}
                                />
                                <InputBox
                                    id="mobNumber"
                                    name="mobNumber"
                                    type="number"
                                    isLabel={true}
                                    labelText="Enter Mobile Number"
                                    placeholder=""
                                    onChange={(e) => {
                                        if (
                                            e.target.value.toString().length >
                                            10
                                        ) {
                                            setValue(
                                                'mobNumber',
                                                e.target.value
                                                    .toString()
                                                    .slice(0, 10)
                                            );
                                            trigger('mobNumber');
                                        } else {
                                            setValue(
                                                'mobNumber',
                                                e.target.value
                                            );
                                        }
                                    }}
                                    value={watch('mobNumber')}
                                    dbName={'mobNumber'}
                                    maxlength={10}
                                    errorMessage={
                                        errors?.mobNumber &&
                                        errors.mobNumber?.message
                                    }
                                    isError={errors?.mobNumber}
                                />
                                <Controller
                                    control={control}
                                    name="acknowledge"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Checkbox
                                            containerClass=" w-full"
                                            checkBoxLabel="I acknowledge that I have read, understood, and agree to abide by the Terms and Conditions and Privacy Policy outlined by the website."
                                            handleChange={onChange}
                                            isSelected={value}
                                            errorMessage={
                                                errors?.acknowledge &&
                                                errors.acknowledge?.message
                                            }
                                            isError={errors?.acknowledge}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="smsConfirmation"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Checkbox
                                            containerClass=" w-full"
                                            checkBoxLabel="Yes, I would like to receive calls, SMS and WhatsApp"
                                            handleChange={onChange}
                                            isSelected={value}
                                            errorMessage={
                                                errors.smsConfirmation &&
                                                errors.smsConfirmation?.message
                                            }
                                            isError={errors?.smsConfirmation}
                                        />
                                    )}
                                />
                            </div>
                            <StickyButton
                                label="Request Callback"
                                handleSubmit={handleSubmit(
                                    handleRequestCallback
                                )}
                                disabled={
                                    !Object.keys(errors) ||
                                    !watch('acknowledge')
                                }
                            />
                        </form>
                    </>
                )}
            </div>
        </SlideOver>
    );
};

RequestCallback.defaultProps = {
    setOpenRequestCallback: () => {},
    isRequestCallback: false,
    register: () => {},
    onClose: () => {},
};

RequestCallback.propTypes = {
    register: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    handleMobileChange: PropTypes.func,
    handleAcknowledge: PropTypes.func,
    isCheckedAcknowledge: PropTypes.bool,
    handleCallSMS: PropTypes.func,
    isCheckedCallSMS: PropTypes.bool,
    handleCallBack: PropTypes.func,
    onClose: PropTypes.func,
    setOpenRequestCallback: PropTypes.func,
    isRequestCallback: PropTypes.bool,
    isOpenRequestCallback: PropTypes.bool,
    isRequestRaised: PropTypes.bool,
};

export default RequestCallback;
