import Image from 'next/image';
import PropTypes from 'prop-types';

const Logo = ({ className, width, height, alt, src }) => {
    return (
        <div className={`cursor-pointer w-fit select-none ${className}`}>
            <Image src={src} width={width} height={height} alt={alt} />
        </div>
    );
};

Logo.defaultProps = {
    className: '',
    width: 117,
    height: 40,
    alt: '',
    src: '',
};

Logo.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
    src: PropTypes.string,
};

export default Logo;
