import { useMutation } from '@tanstack/react-query';
import { createEmailSubscription } from './email-subscription.service';

export const useEmailSubscriptionMutation = () => {
    const createEmailSubscriptionMutation = useMutation({
        mutationFn: ({ leadId, email }) =>
            createEmailSubscription({ leadId, email }),
    });
    return {
        createEmailSubscriptionMutation,
    };
};
