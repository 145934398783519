import { getCookie } from 'cookies-next';

export const getTimeStamp = () => Date.now();

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const checkFor401Error = (error) => {
    const { status } = error.response;
    return status === 401;
};

export const checkForTokenExpiredError = (error) => {
    const { status } = error.response;
    return status === 401;
};

export const parseData = (data) => JSON.parse(data);

export const downloadImages = (imageUrls = []) => {
    imageUrls.forEach(({ url, name }, index) => {
        setTimeout(() => {
            const link = document.createElement('a');
            link.href = url;
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }, index * 2500);
    });
};

export const getFormattedMobileNumber = (mobile) => {
    const numStr = String(mobile);
    return `${numStr.slice(0, 3)}-${numStr.slice(3)}`;
};

export const downloadInvoice = ({ orderId, url }) => {
    let invoice = [
        {
            url,
            name: `${orderId}-invoice.pdf`,
        },
    ];
    downloadImages(invoice);
};

export const getCookieAuth = (ctx) => {
    const cookie = getCookie('AUTH', { req: ctx.req, res: ctx.res });
    return cookie ? parseData(cookie) : {};
};

export function debounce(func, wait, immediate) {
    var timeout;
    return function executedFunction() {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
