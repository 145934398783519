import { create } from 'zustand';

export const useRequestCallbackStore = create((set) => ({
    isOpenRequestCallback: false,
    requestCallbackDescription: '',
    isRequestMessagaeRecieve: false,
    setRequestcallbackDescription: (desc) =>
        set({ requestCallbackDescription: desc }),
    setIsOpenRequestcallback: (isOpen) =>
        set({ isOpenRequestCallback: isOpen }),
    setIsRequestMessagaeRecieve: (check) =>
        set({ isRequestMessagaeRecieve: check }),
}));
