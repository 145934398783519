import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAuth = () => {
    const auth = cookies.get('AUTH');
    return auth;
};

export const setAuth = (authObject, isRemember) => {
    const initialAuth = getAuth() ?? {};
    if (isRemember) {
        const expires = new Date();
        expires.setDate(expires.getDate() + 30);

        cookies.set('AUTH', JSON.stringify({ ...initialAuth, ...authObject }), {
            path: '/',
            expires: expires,
        });
        return authObject;
    }

    cookies.set('AUTH', JSON.stringify({ ...initialAuth, ...authObject }), {
        path: '/',
    });
    return authObject;
};

export const removeAuth = () => {
    const currentAuth = getAuth();
    if (currentAuth && currentAuth.planId) {
        const newAuth = { planId: currentAuth.planId };
        cookies.set('AUTH', JSON.stringify(newAuth), { path: '/' });
    } else {
        cookies.remove('AUTH', { path: '/' });
    }
};

export const setRefreshToken = (refreshToken) => {
    cookies.set('RT', refreshToken, { path: '/' });
    return refreshToken;
};
export const getRefreshToken = (authDetails) => {
    const auth = authDetails ? authDetails.refreshToken : cookies.get('RT');
    return auth;
};
export const removeRT = () => {
    cookies.remove('RT', { path: '/' });
    return;
};

export const isAuthenticated = (user) => user != null && user.token;

export const isUnauthorizedRequest = (auth) => {
    return !auth || !isAuthenticated(JSON.parse(auth));
};
