import PropTypes from 'prop-types';
import GetInTouch from './GetInTouch';
import Image from 'next/image';
import Text from '@/atoms/Text';
import Link from 'next/link';
import NextImage from 'next/image';
import { useState } from 'react';
import ModalWithPdf from './ModalWithPdf';
import { useRouter } from 'next/router';

const Footer = ({
    register,
    handleSubmit,
    email,
    errors,
    showSuccessMessage,
    handleBuyNow,
    footerMenuGroup,
    isShowPrivacyPolicyModal,
    closePrivacyPolicyModal,
    isTermsConditionsShowModal,
    closeTermsConditionsModal,
    privacyPolicyLink,
    termsConditionsLink,
    handlePrivacyPolicy,
    handleTermsConditions,
}) => {
    const [open, setOpen] = useState();
    const router = useRouter();
    const handleScroll = (href) => {
        router.push(href);
        const id = href.split('#')[1];

        setTimeout(() => {
            const targetElement = document.getElementById(id);
            if (targetElement) {
                const navbarHeight =
                    document.querySelector('.navbar.fixed')?.offsetHeight || 0;
                window.scrollTo({
                    top: targetElement.offsetTop - navbarHeight - 50,
                    behavior: 'smooth',
                });
            }
        }, 1000);
    };

    return (
        <>
            <GetInTouch
                errors={errors}
                register={register}
                handleSubmit={handleSubmit}
                handleBuyNow={handleBuyNow}
                email={email}
                showSuccessMessage={showSuccessMessage}
            />
            <footer className="bg-white">
                <div className="container">
                    <div className="flex flex-wrap py-10 gap-y-10 lg:gap-0">
                        <div className="max-w-[348px] mx-auto text-center lg:text-left w-full lg:w-3/12">
                            <div className="md:max-w-[273px] lg:max-w-[360px] mx-auto">
                                <Image
                                    src="/images/logo_footer.svg"
                                    width={360}
                                    height={84}
                                    alt="logo"
                                />
                            </div>

                            <Text variant="caption" className="mt-6 mb-4">
                                Address: Apartment name, Street Name, Name of
                                Area, Name of City, Name of State, Country,
                                Zipcode- XXXXXXXX
                            </Text>
                            <Text
                                variant="body2"
                                fontWeight="font-medium"
                                className="mb-2"
                            >
                                Call us at +91 98765 43210
                            </Text>
                            <Text variant="body2" fontWeight="font-medium">
                                Visit us at: {''}
                                <Link href="https://drreddys.com/">
                                    <span className="text-blue">
                                        drreddys.com
                                    </span>
                                </Link>
                            </Text>
                        </div>
                        <div className="w-full flex-wrap lg:w-9/12 flex gap-y-5 lg:pl-[120px]">
                            {footerMenuGroup.map((menuList, index) => (
                                <div key={index} className="w-full md:w-1/5">
                                    <div
                                        className="flex justify-between items-center cursor-pointer"
                                        onClick={() =>
                                            index === open
                                                ? setOpen(null)
                                                : setOpen(index)
                                        }
                                    >
                                        <Text
                                            variant="body1"
                                            fontWeight="font-medium"
                                            className="mb-3"
                                            textColor="text-grey-shade1"
                                        >
                                            {menuList.title}
                                        </Text>
                                        <NextImage
                                            src="/images/icons/icon_chevron_down.svg"
                                            alt="chevron down"
                                            width={12}
                                            height={6}
                                            className={`transform duration-300 ${index === open ? 'rotate-180' : ' rotate-0'} md:hidden`}
                                        />
                                    </div>

                                    <ul
                                        className={`transition-all duration-300 ${
                                            index === open
                                                ? 'block'
                                                : 'hidden md:block'
                                        }`}
                                    >
                                        {menuList.items.map(
                                            (menuItem, itemIndex) => (
                                                <li key={itemIndex}>
                                                    <a
                                                        href={menuItem.href}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleScroll(
                                                                menuItem.href
                                                            );
                                                        }}
                                                        className="text-blue text-[10px] tracking-[0.2px] font-medium"
                                                    >
                                                        {menuItem.label}
                                                    </a>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-wrap py-6 border-y  border-grey-shade5">
                        <Image
                            src="/images/icons/logo_dailyBloom.svg"
                            width={85}
                            height={21}
                            alt="dailyBloom"
                        />
                        <Text variant="body1" className="ml-1 mr-3">
                            also helps manage:
                        </Text>

                        <div className="flex w-full md:w-auto mt-[18px] md:mt-0 gap-5">
                            <Link
                                href="https://psoriasis-qa-website.svaas.com/
"
                                target="_blank"
                            >
                                <Image
                                    src="/images/icons/psoriasis.svg"
                                    width={118}
                                    height={21}
                                    alt="psoriasis"
                                    className="border-r border-grey-shade5 pr-5"
                                />
                            </Link>
                            <Link
                                href="https://dailybloomibs.com/"
                                target="_blank"
                            >
                                <Image
                                    src="/images/icons/ibs.svg"
                                    width={44}
                                    height={21}
                                    alt="ibs"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="flex flex-wrap flex-col md:flex-row pt-6 pb-10 gap-y-6 md:gap-5">
                        <div className="flex gap-5 justify-center md:justify-start">
                            <Link
                                href="#"
                                className="max-w-[96px] lg:max-w-full"
                            >
                                <Image
                                    src="/images/icons/icon_google_play.svg"
                                    width={100}
                                    height={32}
                                    alt="logo"
                                />
                            </Link>
                            <Link
                                href="#"
                                className="max-w-[96px] lg:max-w-full"
                            >
                                <Image
                                    src="/images/icons/icon_app_store.svg"
                                    width={100}
                                    height={32}
                                    alt="logo"
                                />
                            </Link>
                        </div>

                        <div className="flex items-center justify-center md:justify-start gap-5 mb-2 md:mb-0">
                            <Text
                                variant="caption"
                                textColor="text-blue"
                                fontWeight="font-medium"
                                onClick={handlePrivacyPolicy}
                                className="cursor-pointer"
                            >
                                Privacy policy
                            </Text>
                            <Text
                                variant="caption"
                                textColor="text-blue"
                                fontWeight="font-medium"
                                onClick={handleTermsConditions}
                                className="cursor-pointer"
                            >
                                Terms & Conditions
                            </Text>
                        </div>

                        <div className="flex gap-5 ml-auto mr-auto md:mr-0">
                            <Link href="#">
                                <Image
                                    src="/images/icons/icon_fb.svg"
                                    width={32}
                                    height={32}
                                    alt="facebook"
                                />
                            </Link>
                            <Link href="#">
                                <Image
                                    src="/images/icons/icon_instagram.svg"
                                    width={32}
                                    height={32}
                                    alt="Instagram"
                                />
                            </Link>
                            <Link href="#">
                                <Image
                                    src="/images/icons/icon_tw.svg"
                                    width={32}
                                    height={32}
                                    alt="Twitter"
                                />
                            </Link>
                            <Link href="#">
                                <Image
                                    src="/images/icons/icon_youtube.svg"
                                    width={32}
                                    height={32}
                                    alt="Youtube"
                                />
                            </Link>
                            <Link href="#">
                                <Image
                                    src="/images/icons/icon_linkedin.svg"
                                    width={32}
                                    height={32}
                                    alt="linkedin"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
            <ModalWithPdf
                pdfTitle="Privacy Policy"
                pdfLink={privacyPolicyLink}
                isShowModal={isShowPrivacyPolicyModal}
                closeModal={closePrivacyPolicyModal}
            />
            <ModalWithPdf
                pdfTitle="Terms & Conditions"
                pdfLink={termsConditionsLink}
                isShowModal={isTermsConditionsShowModal}
                closeModal={closeTermsConditionsModal}
            />
        </>
    );
};

Footer.defaultProps = {
    footerMenuGroup: [],
    register: () => {},
    handleSubmit: () => {},
    handleBuyNow: () => {},
};

Footer.propTypes = {
    footerMenuGroup: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    href: PropTypes.string,
                    label: PropTypes.string,
                })
            ),
        })
    ),
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleBuyNow: PropTypes.func,
    isShowPrivacyPolicyModal: PropTypes.bool,
    closePrivacyPolicyModal: PropTypes.func,
    isTermsConditionsShowModal: PropTypes.bool,
    closeTermsConditionsModal: PropTypes.func,
    privacyPolicyLink: PropTypes.string,
    termsConditionsLink: PropTypes.string,
    handlePrivacyPolicy: PropTypes.func,
    handleTermsConditions: PropTypes.func,
    email: PropTypes.string,
    errors: PropTypes.object,
    showSuccessMessage: PropTypes.bool,
};

export default Footer;
